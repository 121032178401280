/**
 * Utility functions related to the handling the DateTime metadata type.
 *
 * See DateUtil.ts for general date utilities.
 */

import { Constants as C } from "core";
import Type = require("Everlaw/Type");
import { P, Precision } from "Everlaw/DateTimePrecision";
import { TimezoneN } from "Everlaw/DateUtil";
import * as moment from "moment-timezone";

/**
 * Split a datetime into date and time. The time zone selected is NOT based on
 * Type.DateTime.timezone, but on the time zone provided
 */
export function split(
    dt: Type.DateTime,
    timezone: TimezoneN,
): { date: Type.DateTime; time: Type.DateTime } {
    const datetime = moment(dt.lower).tz(timezone);
    const startOfDay = moment(datetime).startOf("day");
    const timePart: Type.DateTime = {
        lower: datetime.diff(startOfDay),
        precision: dt.precision & Precision.timeOnly,
    };
    const datePart: Type.DateTime = {
        lower: startOfDay.valueOf(),
        precision: dt.precision & Precision.dateOnly,
    };
    return { date: datePart, time: timePart };
}

/**
 * Remove the seconds field from a date
 */
export function removeSeconds(dt: Type.DateTime): Type.DateTime {
    dt = { ...dt };
    dt.lower = moment(dt.lower).second(0).unix() * C.SEC;
    dt.precision &= ~P.SECOND;
    return dt;
}

export function removeDate(dt: Type.DateTime): Type.DateTime {
    dt = { ...dt };
    dt.lower = moment(dt.lower).date(1).month(0).year(1970).unix() * C.SEC;
    dt.precision &= ~(P.YEAR | P.MONTH | P.DAY);
    return dt;
}
