import dojo_on = require("dojo/on");
import dojo_topic = require("dojo/topic");
import { Constants as C, Is } from "core";
import { ZIndexTokens } from "design-system";
import DateUtil = require("Everlaw/DateUtil");
import Dom = require("Everlaw/Dom");
import Input = require("Everlaw/Input");
import Project = require("Everlaw/Project");
import Type = require("Everlaw/Type");
import UI = require("Everlaw/UI");
import BasicRadio = require("Everlaw/UI/BasicRadio");
import UI_DateBox = require("Everlaw/UI/DateBox");
import FocusContainerWidget = require("Everlaw/UI/FocusContainerWidget");
import UI_FocusGrouping = require("Everlaw/UI/FocusGrouping");
import Popup = require("Everlaw/UI/Popup");
import RangeConstraintManager = require("Everlaw/UI/RangeConstraintManager");
import TimezoneSelect = require("Everlaw/UI/TimezoneSelect");
import UI_Validated = require("Everlaw/UI/Validated");
import Widget = require("Everlaw/UI/Widget");
import { Precision, PrecisionName } from "Everlaw/DateTimePrecision";
import { split } from "Everlaw/DateTimeTypeUtil";
import { USER_DISPLAY_DATE_FORMAT, TimezoneN, TimezoneNO, TimezoneO } from "Everlaw/DateUtil";
import {
    getProjectMomentJSDateFormat,
    getProjectDateDisplayFormat,
    getSearchTimeDisplayFormat,
} from "Everlaw/ProjectDateUtil";
import { makeFocusable } from "Everlaw/UI/FocusDiv";
import * as moment from "moment-timezone";

const DATE_INPUT_WIDTH = "119px";

type DSWValue = Type.DateTimeSearch | Type.NullDateSearch;

enum Mode {
    NONE = "No date/time",
    DATE_ONLY = "Date only",
    BOTH = "Date and time",
    TIME_ONLY = "Time only",
}

const modes = [Mode.BOTH, Mode.DATE_ONLY, Mode.TIME_ONLY, Mode.NONE];

const precisionModeMap = {
    [PrecisionName.dateOnly]: Mode.DATE_ONLY,
    [PrecisionName.dateAndTime]: Mode.BOTH,
    [PrecisionName.timeOnly]: Mode.TIME_ONLY,
};

interface DateSearchWidgetParams {
    // The element the DSW's Popup should be attached to
    popupReference: HTMLElement;
    // The FocusContainerWidget that will be paired with this DSW
    pairedFocusContainer: FocusContainerWidget;
    // Callback for when the exactness of the current input changes
    onExactnessChange?: (isExact: boolean) => void;
    // flag indicating whether time zone should be fixed to project timezone
    fixedTimezone?: boolean;
    // Flag so that if the widget is in date only mode, it'll set the end date time to the last
    // possible time when getting the value. Currently used for Storybuilder event labels.
    expandTimeForDateOnly?: boolean;
}

class DateSearchWidget extends FocusContainerWidget implements Widget.WithSettableValue {
    // The actual UI sub-widgets
    private modesRadio: BasicRadio;
    // Note that tzWidget is constructed lazily for performance because there are too many timezones
    private tzWidget: TimezoneSelect.SelectNO;
    private fromDate: UI_DateBox;
    private fromTime: UI_Validated.Time;
    private toDate: UI_DateBox;
    private toTime: UI_Validated.Time;

    // DateTimeWidgetGroups, which contain fromDate, fromTime, etc above. See DateTimeWidgetGroup.
    private from: DateSearchWidget.DateTimeWidgetGroup;
    private to: DateSearchWidget.DateTimeWidgetGroup;

    // LabeledWidgets, which contain fromDate, fromTime, etc above. See LabeledWidget.
    private fromDateLW: LabeledWidget;
    private fromTimeLW: LabeledWidget;
    private toDateLW: LabeledWidget;
    private toTimeLW: LabeledWidget;

    //Text to be displayed if no date/time option selected
    private noDateTimeText: HTMLElement = Dom.td(
        Dom.p(
            { style: "width: 200px; text-align: left" },
            "Search for documents with no date/time.",
        ),
    );

    // A couple other weird things: See their respective doc comments.
    private popup: Popup;
    private dateRcm: RangeConstraintManager<Type.DateTime, UI_DateBox>;
    private timezoneN: TimezoneN; // Used before tzWidget has been created

    // This only exists to be the target of a few focus-related hacks. It's an element inside this
    // FocusContainerWidget, but is not a visibly-focusable element.
    private hiddenInput: HTMLInputElement;

    // Rows of the "inputs table", which contains the date, time, and time zone widgets
    private row1: HTMLTableRowElement;
    private row2: HTMLTableRowElement;
    private row3: HTMLTableRowElement;
    // This adds space between row1 and row2. It's messier with CSS.
    private spacer: HTMLTableCellElement;

    // (For date-only searches only) We hide tzWidget by default, but give the user an option to
    // edit the time zone if they want to.
    private row4: HTMLDivElement;
    private editTimezonesButton: HTMLElement;
    private tzWidgetContainer: HTMLElement;
    private hasEverEditedTimezone: boolean;
    fixedTimezone: boolean;
    private hideTimezones: boolean;

    // Sub-widgets in the order they should be focused in
    private focusOrder: (UI_DateBox | UI_Validated.Time | (() => { focus: () => void }))[];
    // When this DSW is focused, should it then immediately shift focus to a sub-widget?
    private shouldShiftFocus: boolean;

    private expandTimeForDateOnly: boolean;

    constructor(params: DateSearchWidgetParams, dateTimeOnly?: boolean, hideTimezones = false) {
        super(Dom.div({ class: "date-search-widget" }));

        this.hideTimezones = hideTimezones;
        this.shouldShiftFocus = true;
        this.hasEverEditedTimezone = false;
        this.expandTimeForDateOnly = params.expandTimeForDateOnly;

        this.hiddenInput = Dom.input();

        // Create all the actual UI sub-widgets
        if (dateTimeOnly) {
            const dateTimeOnly = [Mode.DATE_ONLY, Mode.BOTH];
            this.modesRadio = new BasicRadio(dateTimeOnly, true);
        } else {
            this.modesRadio = new BasicRadio(modes, true);
        }
        this.modesRadio.onChange = (mode) => {
            params.onExactnessChange && params.onExactnessChange(this.isExact());
            this.updateInputsTable();
            this.updateTimezoneSelectType(mode.id as Mode);
            this.setMode(mode.id as Mode);
        };

        // The possible date formats for the project assume the month and day will each be
        // displayed as two digits, but for searching we want to support both single digit and two
        // digit days and months, so we use forgiving parsing formats.
        this.fromDate = new UI_DateBox({
            placeholder: USER_DISPLAY_DATE_FORMAT[getProjectDateDisplayFormat()],
            width: DATE_INPUT_WIDTH,
            onSubmit: (val) => this.focusAfter(this.fromDate),
            onBlur: () => this.toDate.input.validate(),
            textBoxLabelContent: "From",
        });
        this.toDate = new UI_DateBox({
            placeholder: USER_DISPLAY_DATE_FORMAT[getProjectDateDisplayFormat()],
            width: DATE_INPUT_WIDTH,
            onSubmit: (val) => this.focusAfter(this.toDate),
            onBlur: () => this.fromDate.input.validate(),
            textBoxLabelContent: "To",
        });
        this.toDate.input.linkErrorMessage(this.fromDate.input);
        Dom.addClass(this.toDate.getForm().errorDiv, "dsw-wide-error");
        const dateDisplayFormat = getProjectMomentJSDateFormat();
        this.fromDate.setDisplayFormat(dateDisplayFormat);
        this.toDate.setDisplayFormat(dateDisplayFormat);
        this.fromDate.setParsingFormat(dateDisplayFormat);
        this.toDate.setParsingFormat(dateDisplayFormat);

        this.fromTime = new UI_Validated.Time({
            name: "start time",
            placeholderMessage: "Start time",
            width: DATE_INPUT_WIDTH,
            onSubmit: (val) => this.focusAfter(this.fromTime),
            required: false,
        });
        this.toTime = new UI_Validated.Time({
            name: "end time",
            placeholderMessage: "End time",
            width: DATE_INPUT_WIDTH,
            onSubmit: (val) => this.focusAfter(this.toTime),
            required: false,
        });
        const timeDisplayFormat = getSearchTimeDisplayFormat();
        this.fromTime.setDisplayFormat(timeDisplayFormat);
        this.toTime.setDisplayFormat(timeDisplayFormat);
        this.toTime.linkErrorMessage(this.fromTime);
        Dom.addClass(this.toTime.errorDiv, "dsw-wide-error");
        Dom.style([this.fromTime.getNode(), this.toTime.getNode()], "width", DATE_INPUT_WIDTH);

        this.fromDate.onSelect = (val, fromKeyboard) => {
            this.updateConstraints(true, val);
            if (!fromKeyboard) {
                this.focusAfter(this.fromDate);
            }
        };
        this.toDate.onSelect = (val, fromKeyboard) => {
            this.updateConstraints(false, val);
            if (!fromKeyboard) {
                this.focusAfter(this.toDate);
            }
        };

        this.fromTime.onChange = (v) => this.updateTimezonesVisibility();
        this.toTime.onChange = (v) => this.updateTimezonesVisibility();

        // Create DateTimeWidgetGroups and LabeledWidgets
        this.from = new DateSearchWidget.DateTimeWidgetGroup(this.fromDate, this.fromTime, () =>
            this.getTimezoneId(),
        );
        this.to = new DateSearchWidget.DateTimeWidgetGroup(this.toDate, this.toTime, () =>
            this.getTimezoneId(),
        );
        this.fromDateLW = new LabeledWidget(this.fromDate);
        this.fromTimeLW = new LabeledWidget(this.fromTime);
        this.toDateLW = new LabeledWidget(this.toDate);
        this.toTimeLW = new LabeledWidget(this.toTime);

        this.dateRcm = new RangeConstraintManager(this.fromDate, this.toDate);
        this.fromDate.rwInfo = {
            other: this.toDate,
            isBegin: true,
        };
        this.toDate.rwInfo = {
            other: this.fromDate,
            isBegin: false,
        };

        Dom.style(this.noDateTimeText, { paddingRight: "8px", paddingTop: "5px" });
        Dom.style(this.fromDate, { marginRight: "8px" });
        Dom.style(this.fromTime, { marginRight: "8px" });

        Dom.place(
            [
                Dom.div({ style: { width: 0, height: 0, overflow: "hidden" } }, this.hiddenInput),
                Dom.table(
                    { class: "dsw-main", style: { width: "486px" } },
                    Dom.tr(
                        Dom.td({ class: "dsw-left" }, this.modesRadio.getNode()),
                        Dom.td(
                            { class: "dsw-right", style: { maxWidth: "311px" } },
                            // the "inputs table", which will later be populated with date and time widgets
                            // based on the selected mode
                            Dom.table(
                                { class: "dsw-inputs-table" },
                                Dom.create("colgroup", {
                                    content: [
                                        Dom.create("col", { span: 1, style: { width: "40px" } }),
                                        Dom.create("col", { span: 1 }),
                                        Dom.create("col", { span: 1, style: { width: "40px" } }),
                                        Dom.create("col", { span: 1 }),
                                    ],
                                }),
                                Dom.create("tbody", {
                                    content: [
                                        (this.row1 = Dom.tr()),
                                        Dom.tr((this.spacer = Dom.td({ class: "dsw-spacer" }))),
                                        (this.row2 = Dom.tr()),
                                        (this.row3 = Dom.tr(
                                            Dom.td(),
                                            Dom.td(
                                                { colspan: 3 },
                                                (this.tzWidgetContainer = Dom.div()),
                                            ),
                                        )),
                                    ],
                                }),
                            ),
                            (this.row4 = Dom.div({ class: "dsw-timezone-info" })),
                        ),
                    ),
                ),
            ],
            this.node,
        );
        this.editTimezonesButton = Dom.a("Edit");
        this.setTimezoneN(); // set to project TZ be default

        Dom.hide(this.spacer);

        if (Is.defined(params.fixedTimezone)) {
            this.fixedTimezone = params.fixedTimezone;
        } else {
            this.fixedTimezone = false;
        }

        if (!Project.CURRENT) {
            Dom.show(this.tzWidgetContainer);
        } else if (!this.fixedTimezone) {
            const editFocusDiv = makeFocusable(this.editTimezonesButton, "focus-text-style");
            this.connect(this.editTimezonesButton, Input.tap, () => {
                this.hasEverEditedTimezone = true;
                this.updateTimezonesVisibility();
                this.getOrCreateTzWidget().openPopup();
            });
            Dom.removeAttr(this.editTimezonesButton, "tabindex");
            this.registerDestroyable([
                editFocusDiv,
                Input.fireCallbackOnKey(editFocusDiv.node, [Input.ENTER], () => {
                    this.hasEverEditedTimezone = true;
                    this.updateTimezonesVisibility();
                    this.getOrCreateTzWidget().openPopup();
                }),
            ]);
        } else {
            Dom.destroy(this.editTimezonesButton);
            if (this.tzWidget) {
                Dom.destroy(Dom.node(this.tzWidget));
                this.tzWidget.destroy();
            }
        }
        this.setMode(Mode.DATE_ONLY);

        if (!Dom.hasAttr(params.popupReference, "tabIndex")) {
            // Make the reference node focusable so that we can scroll to it when focusing the popup.
            Dom.setAttr(params.popupReference, "tabIndex", "-1");
        }
        this.popup = new Popup({
            content: this.node,
            direction: "after",
            reference: params.popupReference,
            attachToMidReference: true,
            //This value has to be less than the zIndex of the select dropdown, but more than our
            //dojo dialogs. The select dropdown has a zIndex of 1000.
            zIndex: String(ZIndexTokens.POPOVER - 1),
            matchWidth: false,
        });

        UI_FocusGrouping.unite(this, params.pairedFocusContainer);
        UI_FocusGrouping.adopt(this, this.fromDate.input);
        UI_FocusGrouping.adopt(this, this.toDate.input);
        this.fixFocusPairingBug();

        const startDragSubscription = dojo_topic.subscribe("/search/startDrag", () => {
            (this as UI_FocusGrouping.FocusGroupable).onBlur(true);
        });

        this.registerDestroyable([
            this.popup,
            this.fromDate,
            this.fromTime,
            this.toDate,
            this.toTime,
            this.modesRadio,
            startDragSubscription,
        ]);
    }
    override selfPlacedInDom() {
        return true;
    }
    private updateRow4(timezone?: TimezoneN) {
        Dom.setContent(
            this.row4,
            timezone
                ? ["Using time zone: ", Dom.br(), timezone, " ", this.editTimezonesButton]
                : Project.CURRENT
                  ? [
                        "Using the project time zone: ",
                        Dom.br(),
                        Project.CURRENT.timezoneId,
                        " ",
                        this.editTimezonesButton,
                    ]
                  : "",
        );
    }
    private getOrCreateTzWidget() {
        if (this.tzWidget) {
            return this.tzWidget;
        }
        this.tzWidget = new TimezoneSelect.SelectNO({
            n: {
                onSelect: (value) => {
                    this.blur();
                    this.fromDate.timezone = value.id;
                    this.toDate.timezone = value.id;
                },
                textBoxAriaLabel: "Time zone, Enter portion of name",
            },
            o: {
                onSelect: () => {
                    this.blur();
                },
                textBoxAriaLabel: "Time zone, Enter portion of name",
            },
        });
        // TODO: This widget uses tables for creating a layout. It might be worth changing to
        // flexbox (or inline-block divs for the "inputs table")
        Dom.addClass(this.tzWidget, "dsw-timezone-select");
        Dom.place(this.tzWidget, this.tzWidgetContainer);
        this.registerDestroyable(this.tzWidget);
        this.timezoneN && this.tzWidget.setTimezone(this.timezoneN);
        this.tzWidget.showTimezoneSelectN(this.modesRadio.getSelectedId() !== Mode.TIME_ONLY);
        return this.tzWidget;
    }
    private updateTimezonesVisibility() {
        if (!Project.CURRENT) {
            return;
        }
        let show;
        if (this.modeIs(Mode.DATE_ONLY)) {
            if (this.tzWidget && this.tzWidget.getTimezoneId() !== Project.CURRENT.timezoneId) {
                this.hasEverEditedTimezone = true;
            }
            show = this.hasEverEditedTimezone;
        } else {
            show = this.hasEverEditedTimezone;
        }
        Dom.show(this.row4, !show);
        Dom.show(this.row3, show);
    }
    /**
     * The "inputs table" is a 2 (rows) x 4 (columns) table used for displaying the inputs and their
     * labels.
     *
     * This method mainly just shuffles the pieces around depending on the current mode. It also
     * handles a bit of other display logic dependent on the mode.
     */
    private updateInputsTable() {
        if (!this.fixedTimezone) {
            this.updateTimezonesVisibility();
        }
        if (this.modeIs(Mode.BOTH)) {
            Dom.remove(this.noDateTimeText);
            this.fromDateLW.placeIn(this.row1, false, this.fromDate.getForm().input.getLabel());
            this.fromTime.setTextBoxLabelContent("at");
            this.fromTimeLW.placeIn(this.row2, false, this.fromTime.input.getLabel());
            this.toDateLW.placeIn(this.row1, false, this.toDate.getForm().input.getLabel());
            this.toTime.setTextBoxLabelContent("at");
            this.toTimeLW.placeIn(this.row2, false, this.toTime.input.getLabel());
            Dom.show(this.tzWidgetContainer);
            this.tzWidget && this.tzWidget.setDisabled(false);
            this.focusOrder = [
                this.fromDate,
                this.fromTime,
                this.toDate,
                this.toTime,
                () => (this.fixedTimezone ? null : this.getOrCreateTzWidget()),
            ];
            Dom.show(this.spacer);
        } else if (this.modeIs(Mode.DATE_ONLY)) {
            Dom.remove(this.noDateTimeText);
            this.fromDateLW.placeIn(this.row1, false, this.fromDate.getForm().input.getLabel());
            this.toDateLW.placeIn(this.row1, false, this.toDate.getForm().input.getLabel());
            this.fromTimeLW.hide();
            this.toTimeLW.hide();
            Dom.show(this.tzWidgetContainer);
            this.tzWidget && this.tzWidget.setDisabled(false);
            this.focusOrder = [
                this.fromDate,
                this.toDate,
                // tzWidget is hidden by default, only focus if it has been created by user choice
                () => this.tzWidget,
            ];
            Dom.hide(this.spacer);
        } else if (this.modeIs(Mode.TIME_ONLY)) {
            Dom.remove(this.noDateTimeText);
            this.fromTime.setTextBoxLabelContent("From");
            this.fromTimeLW.placeIn(this.row1, false, this.fromTime.input.getLabel());
            this.toTime.setTextBoxLabelContent("To");
            this.toTimeLW.placeIn(this.row1, false, this.toTime.input.getLabel());
            this.fromDateLW.hide();
            this.toDateLW.hide();
            Dom.show(this.tzWidgetContainer);
            this.tzWidget && this.tzWidget.setDisabled(false);
            this.focusOrder = [
                this.fromTime,
                this.toTime,
                () => (this.fixedTimezone ? null : this.getOrCreateTzWidget()),
            ];
            Dom.hide(this.spacer);
        } else if (this.modeIs(Mode.NONE)) {
            Dom.place(this.noDateTimeText, this.row1);
            this.fromDateLW.hide();
            this.toDateLW.hide();
            this.fromTimeLW.hide();
            this.toTimeLW.hide();
            this.tzWidget && this.tzWidget.setDisabled(true);
            Dom.hide(this.tzWidgetContainer);
            this.focusOrder = [];
            Dom.hide(this.spacer);
        }
    }
    private focusAfter(current: UI_DateBox | UI_Validated.Time) {
        const i = this.focusOrder.indexOf(current);
        const next = i + 1;
        if (i === -1 || next === this.focusOrder.length) {
            return;
        }
        const objOrFunc = this.focusOrder[next];
        const focusable = Is.func(objOrFunc) ? objOrFunc() : objOrFunc;
        focusable && focusable.focus();
    }
    private modeIs(mode: Mode) {
        const val = this.modesRadio.getValue();
        return !!val && val.id === mode;
    }
    private isExact(): boolean {
        return this.modeIs(Mode.NONE);
    }
    getForms() {
        return [this.fromDate.input, this.toDate.input, this.fromTime, this.toTime];
    }
    getValue(): DSWValue {
        if (this.modeIs(Mode.NONE)) {
            return Type.DateTimeType.NULL_VALUE;
        } else {
            let from: Type.DateTime;
            let to: Type.DateTime;
            let result: Type.DateTimeSearch = {
                type: null,
                timezone: this.getTimezoneId(),
            };
            if (this.modeIs(Mode.DATE_ONLY)) {
                from = toEverDate(this.from.getDateOnly());
                to = toEverDate(this.to.getDateOnly());
                if (this.expandTimeForDateOnly && from && to) {
                    const tz = this.getTimezoneId() as DateUtil.TimezoneN;
                    from.lower = DateUtil.addUTCOffset(from.lower, tz);
                    to.lower = DateUtil.addUTCOffset(to.lower + C.DAY - 1, tz);
                }
                result.type = PrecisionName.dateOnly;
            } else if (this.modeIs(Mode.BOTH)) {
                if (this.isValidDateTimeSearch()) {
                    from = toEverDatetime(this.from.getValue());
                    to = toEverDatetime(this.to.getValue());
                    result.type = PrecisionName.dateAndTime;
                } else {
                    result = null;
                }
            } else if (this.modeIs(Mode.TIME_ONLY)) {
                from = toEverTime(this.from.getTimeOnly());
                to = toEverTime(this.to.getTimeOnly());
                result.type = PrecisionName.timeOnly;
            }
            UI.createRange(from, to, result);
            return result;
        }
    }

    getPrecision(): PrecisionName {
        if (this.modeIs(Mode.DATE_ONLY)) {
            return PrecisionName.dateOnly;
        } else if (this.modeIs(Mode.TIME_ONLY)) {
            return PrecisionName.timeOnly;
        } else if (this.modeIs(Mode.BOTH)) {
            return PrecisionName.dateAndTime;
        } else {
            return null;
        }
    }
    /**
     * If (in date-time mode) the user fills in a date but not a time (or vice versa), we the search
     * should be invalid.
     */
    private isValidDateTimeSearch() {
        return (
            this.from.isDateSet() === this.from.isTimeSet()
            && this.to.isDateSet() === this.to.isTimeSet()
        );
    }
    getTimezoneId(): TimezoneNO {
        return this.tzWidget ? this.tzWidget.getTimezoneId() : this.timezoneN;
    }
    setValue(val: DSWValue): void {
        this.shouldShiftFocus = false;
        if (Type.DATE_TIME_SEARCH.isValidValue(val)) {
            const timezone = Type.DATE_TIME_SEARCH.getTimezone(val);
            this.setModeFromType(val.type);
            this.setFromValue(val.begin, timezone);
            this.setToValue(val.end, timezone);
            this.setTimezoneN(timezone as TimezoneN);
            if (val.type === PrecisionName.dateOnly) {
                this.updateTimezonesVisibility();
            }
        } else if (Type.isNullDateSearch(val)) {
            this.setMode(Mode.NONE);
        }
    }
    private setModeFromType(precision: PrecisionName) {
        const mode = precisionModeMap[precision];
        this.setMode(mode);
    }
    private setMode(mode: Mode) {
        this.modesRadio.select(mode);
        this.updateInputsTable();
        this.updateTimezoneSelectType(mode);

        if (this.hideTimezones && this.modeIs(Mode.DATE_ONLY)) {
            Dom.hide(this.row4);
            Dom.hide(this.row3);
        } else {
            this.updateTimezonesVisibility();
        }
    }
    private updateTimezoneSelectType(mode: Mode) {
        if (
            this.fixedTimezone
            || (!this.tzWidget && (mode === Mode.NONE || mode === Mode.DATE_ONLY))
        ) {
            // Intentionally don't create tzWidget here.
            return;
        }
        this.getOrCreateTzWidget().showTimezoneSelectN(mode !== Mode.TIME_ONLY);
    }
    private setFromValue(val: Type.DateTime, timezone: TimezoneNO) {
        this._setValue(val, this.fromDate, this.fromTime, timezone);
        this.toDate.setMin(val);
    }
    private setToValue(val: Type.DateTime, timezone: TimezoneNO) {
        this._setValue(val, this.toDate, this.toTime, timezone);
        this.fromDate.setMax(val);
    }
    private _setValue(
        val: Type.DateTime,
        datebox: UI_DateBox,
        timebox: UI_Validated.Time,
        timezone: TimezoneNO,
    ) {
        if (!val) {
            return;
        }

        switch (val.precision) {
            case Precision.dateOnly:
                datebox.timezone = timezone as TimezoneN;
                datebox.setValue(val);
                break;
            case Precision.dateTimeNoSeconds:
                const { date, time } = split(val, timezone as TimezoneN);
                datebox.timezone = timezone as TimezoneN;
                datebox.setValue(date);
                timebox.setTime(time.lower);
                break;
            case Precision.timeOnlyNoSeconds:
                timebox.setTime(
                    DateUtil.convertTime(val.lower, "UTC|UTC" as TimezoneO, timezone as TimezoneO),
                );
                break;
        }
    }
    override focus(): void {
        this.popup.reference.focus();
        this.popup.show();
        if (this.shouldShiftFocus) {
            this.shouldShiftFocus = false;
            this.focusInner();
        } else {
            this.hiddenInput.focus();
        }
        this.checkEmptyFields();
        // Create the timezone widget for BOTH or TIME_ONLY modes.
        if (
            !this.fixedTimezone
            && !this.tzWidget
            && (this.modeIs(Mode.BOTH) || this.modeIs(Mode.TIME_ONLY))
        ) {
            this.getOrCreateTzWidget();
        }
    }
    private checkEmptyFields() {
        if (this.modeIs(Mode.BOTH)) {
            [this.from, this.to].forEach((group: DateSearchWidget.DateTimeWidgetGroup) => {
                if (group.isDateSet() && !group.isTimeSet()) {
                    this.showRequired(group.timeWidget);
                } else if (group.isTimeSet() && !group.isDateSet()) {
                    this.showRequired(group.dateWidget);
                }
            });
        }
    }
    /**
     * Given an (empty) widget, show Dojo's red "your input is invalid" popup. If the user interacts
     * with the widget at all, the popup will disappear (even if they click on it and then decide to
     * leave it empty).
     */
    private showRequired(widget: UI_DateBox | UI_Validated.Time) {
        widget.require(true);

        // Focus to show the red "this field is required but you left it blank" popup, then
        // focus hiddenInput because we don't *really* want widget to be focused.
        widget.focus();
        this.hiddenInput.focus();

        // We probably don't really want to make the widget required in the long-term.
        widget.require(false);
    }
    private focusInner(): void {
        if (this.focusOrder[0]) {
            const objOrFunc = this.focusOrder[0];
            const focusable = Is.func(objOrFunc) ? objOrFunc() : objOrFunc;
            focusable.focus();
        } else {
            this.hiddenInput.focus();
        }
    }
    override onBlur(): void {
        this.hide();
        this.maybeFillDefaultMidnight();

        // I'm not sure why, but in some cases (specifically: pick a date with the mouse, then
        // delete it with the keyboard, then click outside of the DSW) clicking out of the DSW with
        // the dijit date picker open will keep the date picker (erroneously) open and send it to
        // the top-left corner of the screen. So we manually close the date picker if it's open by
        // sending blur events manually.
        this.fromDate.blur();
        this.toDate.blur();
    }
    private maybeFillDefaultMidnight() {
        if (this.modeIs(Mode.TIME_ONLY)) {
            if (this.from.isTimeSet() && !this.to.isTimeSet() && this.toTime.getValue() === "") {
                this.to.timeWidget.setTime(0);
            }
            if (this.to.isTimeSet() && !this.from.isTimeSet() && this.fromTime.getValue() === "") {
                this.from.timeWidget.setTime(0);
            }
        } else if (this.modeIs(Mode.BOTH)) {
            if (
                this.from.isDateSet()
                && !this.from.isTimeSet()
                && this.fromTime.getValue() === ""
            ) {
                this.from.timeWidget.setTime(0);
            }
            if (this.to.isDateSet() && !this.to.isTimeSet() && this.toTime.getValue() === "") {
                this.to.timeWidget.setTime(C.DAY - C.MIN);
            }
        }
    }
    hide() {
        this.popup.hide();
    }
    setWidth(width: string): void {}
    updateConstraints(beginChanged: boolean, val: Type.DateTime) {
        // "Constraints" here refers to checking range validity: i.e. end date must be after (or
        // equal to) start date (and vice versa).
        //
        // Ideal constraint behavior depends on which Mode is being used:
        //
        // DATE_ONLY:
        //   This should behave just like UI.DateRangeWidget.
        // TIME_ONLY:
        //   No constraints at all. (Because time is cyclical, we both 9am-5pm and 5pm-9am are valid
        //   ranges.)
        // BOTH: (Not implemented)
        //   This is slightly more complex. For the most part, it's the same as DATE_ONLY, but when
        //   the start and end dates are the same, the time should be constrained (so e.g. end time
        //   must be after start time). Currently not implemented, and just acts like DATE_ONLY.
        this.dateRcm.valueChange(beginChanged, val);
    }

    /**
     * I'm not sure why, but (if you omit this method call) the
     * focus pairing fails in one case: If you click into the field selector and
     * then back to the DateSearchWidget (not on any particular sub-widget, just on the
     * background of the DSW), then the DSW (& search term) receive a blur event, causing the
     * DSW to disappear & search term summary to appear. This is the fix for that bug.
     */
    private fixFocusPairingBug() {
        Dom.setAttr(this.node, "tabindex", "-1");
        this.registerDestroyable(
            dojo_on(this.node, "focus", () => {
                this.hiddenInput.focus();
            }),
        );
    }

    disablePopup() {
        this.popup.positionPopup = () => {};
        this.popup = null;
        this.hide = () => {};
        this.onBlur = () => {};
        this.node.style.position = "static";
        this.fixFocusPairingBug();
    }

    dateAndTimeOnly() {
        const dateTimeOnly = [Mode.DATE_ONLY, Mode.BOTH];
        this.modesRadio = new BasicRadio(dateTimeOnly, true);
    }

    setTimezoneN(timezone?: TimezoneN) {
        if (!timezone && Project.CURRENT) {
            timezone = Project.CURRENT.timezoneId as TimezoneN;
        }
        if (this.tzWidget) {
            this.tzWidget.setTimezone(timezone);
        } else if (!Project.CURRENT && !this.tzWidget) {
            // For org search, always display timezone selector because there is no project default
            this.tzWidget = this.getOrCreateTzWidget();
        } else {
            this.timezoneN = timezone;
        }
        this.updateRow4(
            !Project.CURRENT || timezone !== Project.CURRENT.timezoneId ? timezone : null,
        );
    }
}

class LabeledWidget {
    labelCell: HTMLTableDataCellElement;
    widgetCell: HTMLTableDataCellElement;
    nodes: HTMLElement[];
    constructor(private widget: UI_DateBox | UI_Validated.Time) {
        this.labelCell = Dom.td({ class: "date-time-label" }, "?");
        this.widgetCell = Dom.td(widget.getNode());
        this.nodes = [this.labelCell, this.widgetCell];
    }
    placeIn(row: HTMLTableRowElement, disabled: boolean, labelContent: Dom.Content) {
        Dom.setContent(this.labelCell, labelContent);
        Dom.place(this.nodes, row);
        Dom.show(this.nodes);
        this.setDisabled(disabled);
    }
    hide() {
        Dom.remove(this.nodes);
    }
    setDisabled(disabled: boolean) {
        if (this.widget instanceof UI_DateBox) {
            this.widget.setDisabled(disabled);
        } else {
            this.widget.setDisabled(disabled);
        }
    }
}

module DateSearchWidget {
    export class DateTimeWidgetGroup {
        constructor(
            public dateWidget: UI_DateBox,
            public timeWidget: UI_Validated.Time,
            public getTimezoneId: () => string,
        ) {}

        getDateOnly(): moment.Moment {
            if (!this.isDateSet() || !this.isZoneSet()) {
                return null;
            }
            const date = moment(this.dateWidget.getDate());
            return DateUtil.reinterpretTimezone(date, this.getTimezoneId() as TimezoneN);
        }
        getTimeOnly(): number {
            if (!this.isTimeSet() || !this.isZoneSet()) {
                return null;
            }
            const localTime = this.timeWidget.getParsedValue().msecOfDay;
            return DateUtil.convertTime(
                localTime,
                this.getTimezoneId() as TimezoneO,
                "UTC|UTC" as TimezoneO,
            );
        }
        getValue(): moment.Moment {
            if (!this.isDateSet() || !this.isTimeSet() || !this.isZoneSet()) {
                return null;
            }
            const date = this.dateWidget.getDate();
            const time = this.timeWidget.getParsedValue().msecOfDay;
            const datetime = moment(DateUtil.combine(date, time));
            return DateUtil.reinterpretTimezone(datetime, this.getTimezoneId() as TimezoneN);
        }

        isDateSet() {
            return !!this.dateWidget.getDate();
        }
        isTimeSet() {
            return this.timeWidget.getParsedValue().valid;
        }
        isZoneSet() {
            return !!this.getTimezoneId();
        }
    }

    export class LabeledWidget {
        labelCell: HTMLTableDataCellElement;
        widgetCell: HTMLTableDataCellElement;
        node: HTMLElement;
        constructor(
            private widget: UI_DateBox | UI_Validated.Time | TimezoneSelect.SelectNO,
            layout: "horizonal" | "vertical" = "horizonal",
        ) {
            if (layout === "horizonal") {
                this.node = Dom.div([
                    (this.labelCell = Dom.td({ class: "date-time-label" }, "?")),
                    (this.widgetCell = Dom.td(widget.getNode())),
                ]);
            } else {
                this.node = Dom.table([
                    Dom.tr((this.labelCell = Dom.td({ style: { "font-weight": 600 } }, "?"))),
                    Dom.tr((this.widgetCell = Dom.td(widget.getNode()))),
                ]);
            }
        }
        placeIn(elem: HTMLElement, disabled: boolean, labelContent: Dom.Content) {
            Dom.setContent(this.labelCell, labelContent);
            Dom.place(this.node, elem);
            Dom.show(this.node);
            this.setDisabled(disabled);
        }
        hide() {
            Dom.remove(this.node);
        }
        setDisabled(disabled: boolean) {
            if (
                this.widget instanceof UI_DateBox
                || this.widget instanceof TimezoneSelect.SelectNO
            ) {
                this.widget.setDisabled(disabled);
            } else {
                this.widget.setDisabled(disabled);
            }
        }
    }
}

function toEverDate(m: moment.Moment): Type.DateTime {
    if (m === null) {
        return null;
    }
    return {
        lower: m.valueOf(),
        precision: Precision.dateOnly,
    };
}

function toEverDatetime(m: moment.Moment): Type.DateTime {
    if (m === null) {
        return null;
    }
    return {
        lower: m.valueOf(),
        precision: Precision.dateTimeNoSeconds,
    };
}

/**
 * @param t is milliseconds since start of day
 */
function toEverTime(t: number): Type.DateTime {
    if (t === null) {
        return null;
    }
    return {
        lower: t,
        precision: Precision.timeOnlyNoSeconds,
    };
}

export = DateSearchWidget;
