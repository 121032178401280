import dojo_on = require("dojo/on");
import { Is } from "core";
import DateUtil = require("Everlaw/DateUtil");
import DateBox = require("Everlaw/UI/DateBox");
import Dom = require("Everlaw/Dom");
import Input = require("Everlaw/Input");
import Project = require("Everlaw/Project");
import Type = require("Everlaw/Type");
import BasicRadio = require("Everlaw/UI/BasicRadio");
import UI_DateBox = require("Everlaw/UI/DateBox");
import DateSearchWidget = require("Everlaw/UI/DateSearchWidget");
import FocusContainerWidget = require("Everlaw/UI/FocusContainerWidget");
import FocusGrouping = require("Everlaw/UI/FocusGrouping");
import Popup = require("Everlaw/UI/Popup");
import TimezoneSelect = require("Everlaw/UI/TimezoneSelect");
import Validated = require("Everlaw/UI/Validated");
import Util = require("Everlaw/Util");
import Widget = require("Everlaw/UI/Widget");
import { mapPrecisionName, P, Precision, PrecisionName } from "Everlaw/DateTimePrecision";
import { split } from "Everlaw/DateTimeTypeUtil";
import { DateDisplayFormat, MOMENT_JS_DATE_FORMAT, TimezoneN, TimezoneNO } from "Everlaw/DateUtil";
import { getProjectMomentJSDateFormat, getProjectDateDisplayFormat } from "Everlaw/ProjectDateUtil";
import * as moment from "moment-timezone";

export interface DateTimeRangeParams {
    parent: HTMLElement;
    initialBegin?: number;
    initialEnd?: number;
    precision?: Precision;
    expandTimeForDateOnly?: boolean;
}

/**
 * This class is in another file to avoid circular dependency
 */
export class DateTimeRangeWidget {
    static defaultWidth = "110px";
    type = Type.DATE_TIME;
    startsDiv: HTMLElement;
    container: FocusContainerWidget;
    dateSearch: DateSearchWidget;
    onChange: () => void;

    constructor(params: DateTimeRangeParams) {
        this.startsDiv = params.parent;
        this.container = new FocusContainerWidget(this.startsDiv);
        this.dateSearch = new DateSearchWidget(
            {
                popupReference: this.startsDiv,
                pairedFocusContainer: this.container,
                onExactnessChange: (isExact: boolean) => {},
                fixedTimezone: false,
                expandTimeForDateOnly: params.expandTimeForDateOnly,
            },
            true,
            true,
        );
        Dom.style(this.dateSearch, {
            borderStyle: "none",
            padding: "0px",
            boxShadow: "none",
        });

        // prepopulate search dates if needed
        if (
            Is.defined(params.initialBegin)
            && Is.defined(params.initialEnd)
            && Is.defined(params.precision)
        ) {
            const preRange = {
                begin: { lower: params.initialBegin, precision: params.precision },
                end: { lower: params.initialEnd, precision: params.precision },
                type: mapPrecisionName(params.precision),
                timezone: Project.CURRENT.timezoneId as DateUtil.TimezoneN,
            };
            this.dateSearch.setValue(preRange as Type.DateTimeSearch);
        }
        Dom.place(this.dateSearch, this.startsDiv);
        this.dateSearch.focus();
        this.dateSearch.disablePopup();
        // now remove absolute position
        this.dateSearch.getNode().style.position = "static";
    }
    getValue() {
        const precision = this.dateSearch.getPrecision() || PrecisionName.dateAndTime;
        return {
            ...this.dateSearch.getValue(),
            type: precision,
            timezone: Project.CURRENT
                ? (Project.CURRENT.timezoneId as DateUtil.TimezoneN)
                : this.dateSearch.getTimezoneId(),
        };
    }
    destroy() {
        this.dateSearch.destroy();
        this.container.destroy();
    }
    dateRangeValid(): boolean {
        const dateValue = this.getValue();
        return !Type.isNullDateSearch(dateValue) && !!(dateValue.begin && dateValue.end);
    }
}

/**
 * Wrapper class for the DateTimeWidget to allow initializing with div to place in, and avoids
 * forced extension of FocusContainerWidget
 */
export class DateTimeWrapper {
    focusContainer: FocusContainerWidget;
    widget: DateTimeWidget;

    constructor(
        div: HTMLElement,
        onSubmit?: (time: Type.DateTime) => void,
        compact: boolean = false,
    ) {
        this.focusContainer = new FocusContainerWidget(Dom.div());
        Dom.place(this.focusContainer, div);
        if (compact) {
            this.widget = new DateTimeWidgetCompact(div, onSubmit);
        } else {
            this.widget = new DateTimeWidgetFull(div, onSubmit);
        }
        FocusGrouping.unite(this.widget, this.focusContainer);
        this.focusContainer.onBlur = () => {
            this.widget.onBlur();
        };
    }
}

export enum Mode {
    BOTH = "Date and time",
    DATE_ONLY = "Date only",
    MONTH_ONLY = "Month only",
    YEAR_ONLY = "Year only",
}

const allModes = [Mode.DATE_ONLY, Mode.BOTH, Mode.MONTH_ONLY, Mode.YEAR_ONLY];

const dateModes = [Mode.DATE_ONLY, Mode.BOTH];

export class DateTimeForm extends Widget {
    protected dateBox: UI_DateBox;
    protected monthBox: UI_DateBox; // for month only
    protected yearBox: UI_DateBox; // for year only
    protected timeBox: Validated.Time;

    // Note that tzWidget is constructed lazily for performance because there are too many timezones
    protected tzWidget: TimezoneSelect.SelectNO;

    // Since tzWidget is lazily constructed, we need a place to store the current timezone
    protected timezoneId: TimezoneN;

    // If not null, then use local timezone as default timezone
    // See defaultTimezoneId for more information
    protected readonly localTimezoneId: TimezoneN;

    // Labelled widgets
    protected dateLW: DateSearchWidget.LabeledWidget;
    protected monthLW: DateSearchWidget.LabeledWidget;
    protected yearLW: DateSearchWidget.LabeledWidget;
    protected timeLW: DateSearchWidget.LabeledWidget;
    protected tzLW: DateSearchWidget.LabeledWidget;

    // HTML elements for the layout
    // Initialized in initDOM()
    protected dateboxContainer: HTMLElement;
    protected timeboxContainer: HTMLElement;
    protected tzWidgetContainer: HTMLElement;
    protected tzInfo: HTMLElement;
    protected editTimezonesButton: HTMLElement;

    protected hasEverEditedTimezone: boolean;

    // Current calendar in use, for getValue()
    protected currBox: UI_DateBox;

    protected initialMode: Mode;
    // Current Mode: Date/Datetime/Month/Year
    protected mode: Mode;
    protected onBlur: () => void = () => {};
    protected onChange: (val: any) => void;

    constructor(params?: DateTimeWidgetParams) {
        super();
        this.initialMode = params?.mode || Mode.DATE_ONLY;
        this.localTimezoneId = params?.localTimezone ? (moment.tz.guess() as TimezoneN) : null;
        if (params?.localTimezone) {
            this.timezoneId = moment.tz.guess() as TimezoneN;
        }
        this.initDOM(params?.attrs);
        if (params?.noEditButton) {
            this.editTimezonesButton = null;
        }
        this.connect(this.editTimezonesButton || this.tzInfo, Input.tap, () => {
            this.hasEverEditedTimezone = true;
            this.updateTimezonesVisibility();
            this.getOrCreateTzWidget().openPopup();
        });

        const transferFocus = () => {
            if (this.modeIs(Mode.DATE_ONLY) && !this.dateBox.input.isEmpty()) {
                this.onBlur();
            } else {
                this.timeBox.focus();
            }
        };

        // time and date initializations here
        // also set timezones to project timezone
        const dateBoxParams: UI_DateBox.Params = {
            placeholder: DateUtil.USER_DISPLAY_DATE_FORMAT[getProjectDateDisplayFormat()],
            width: "100%",
            onSubmit: transferFocus,
            onChange: (val) => {
                !!this.onChange && this.onChange(val);
            },
        };
        if (!params?.noLabels) {
            dateBoxParams.textBoxLabelContent = "Date";
            dateBoxParams.width = "140px";
        }
        this.dateBox = new UI_DateBox(dateBoxParams);
        this.dateBox.calendar.onBlur = () => {
            this.dateBox.flushPartialDate();
        };
        const dateDisplayFormat = getProjectMomentJSDateFormat();
        this.dateBox.setDisplayFormat(dateDisplayFormat);
        this.dateBox.setParsingFormat(dateDisplayFormat);
        this.dateBox.timezone = this.getTimezoneId();
        this.dateBox.onSelect = transferFocus;
        Dom.addClass(this.dateBox.input.errorDiv, "dsw-error-message");
        if (dateDisplayFormat === MOMENT_JS_DATE_FORMAT[DateDisplayFormat.YMD]) {
            const monthBoxParams: UI_DateBox.Params = {
                placeholder: "yyyy/mm",
                format: UI_DateBox.View.MONTHS,
                onSubmit: this.onBlur,
                onChange: (val) => {
                    !!this.onChange && this.onChange(val);
                },
            };
            if (!params?.noLabels) {
                monthBoxParams.textBoxLabelContent = "Month";
                monthBoxParams.width = "130px";
            }
            this.monthBox = new UI_DateBox(monthBoxParams);
            this.monthBox.setDisplayFormat("YYYY/MM");
            this.monthBox.setParsingFormat("YYYY/MM");
        } else {
            const monthBoxParams: UI_DateBox.Params = {
                placeholder: "mm/yyyy",
                format: UI_DateBox.View.MONTHS,
                onSubmit: this.onBlur,
            };
            if (!params?.noLabels) {
                monthBoxParams.textBoxLabelContent = "Month";
                monthBoxParams.width = "130px";
            }
            this.monthBox = new UI_DateBox(monthBoxParams);
            this.monthBox.setDisplayFormat("MM/YYYY");
            this.monthBox.setParsingFormat("MM/YYYY");
        }
        this.monthBox.timezone = this.getTimezoneId();
        this.monthBox.onSelect = () => {
            if (!this.monthBox.input.isEmpty()) {
                this.onBlur();
            }
        };
        this.yearBox = new UI_DateBox({ placeholder: "yyyy", format: UI_DateBox.View.YEARS });
        Dom.addClass(this.monthBox.input.errorDiv, [
            "dsw-error-message",
            "dsw-error-horizontal-six",
        ]);
        const yearBoxParams: UI_DateBox.Params = {
            placeholder: "yyyy",
            format: UI_DateBox.View.YEARS,
            onSubmit: () => {
                if (!this.yearBox.input.isEmpty()) {
                    this.onBlur();
                }
            },
            onChange: (val) => {
                !!this.onChange && this.onChange(val);
            },
        };
        if (!params?.noLabels) {
            yearBoxParams.textBoxLabelContent = "Year";
            yearBoxParams.width = "140px";
        }
        this.yearBox = new UI_DateBox(yearBoxParams);
        this.yearBox.timezone = this.getTimezoneId();
        Dom.addClass(this.yearBox.input.errorDiv, "dsw-error-message");
        const timeBoxParams: Validated.DateWidgetParams = {
            name: "time box",
            onSubmit: () => {
                this.onBlur();
            },
            onChange: (val) => {
                this.updateTimezonesVisibility();
                !!this.onChange && this.onChange(val);
            },
            required: false,
        };
        if (!params?.noLabels) {
            timeBoxParams.textBoxLabelContent = "Time";
            timeBoxParams.width = "100px";
        }
        this.timeBox = new Validated.Time(timeBoxParams);
        Dom.addClass(this.timeBox.errorDiv, "dsw-error-message");

        this.initLabelledWidgets();

        this.setTimezone(); // set default timezone for all children widgets

        // Fixes the issue of the widget closing upon date selection without selecting a time
        const onblur = this.dateBox.calendar.onBlur;
        this.dateBox.calendar.onBlur = () => {
            if (!this.modeIs(Mode.BOTH) || !this.timeBox.isEmpty()) {
                onblur();
            }
        };

        this.setMode(this.initialMode);

        this.registerDestroyable([this.dateBox, this.monthBox, this.yearBox, this.timeBox]);
    }

    getParsingFormats(): string[] {
        return this.currBox.input.parsingFormats;
    }

    // setup DOM here
    protected initDOM(attrs?: any) {
        this.node = Dom.div(attrs, [
            Dom.table(
                { class: "date-time-widget__inputs-table" },
                Dom.create("colgroup", {
                    content: [Dom.create("col", { span: 1, style: { width: "40px" } })],
                }),
                Dom.create("tbody", {
                    content: [
                        (this.dateboxContainer = Dom.tr()),
                        Dom.tr(Dom.td({ class: "date-time-widget__spacer" })),
                        (this.timeboxContainer = Dom.tr()),
                    ],
                }),
            ),
            (this.tzWidgetContainer = Dom.div({ class: "date-time-widget__timezone-container" })),
            (this.tzInfo = Dom.div({ class: "date-time-widget__timezone-info" })),
        ]);
        this.editTimezonesButton = Dom.a("Edit");
    }

    protected initLabelledWidgets() {
        this.dateLW = new DateSearchWidget.LabeledWidget(this.dateBox);
        this.monthLW = new DateSearchWidget.LabeledWidget(this.monthBox);
        this.yearLW = new DateSearchWidget.LabeledWidget(this.yearBox);
        this.timeLW = new DateSearchWidget.LabeledWidget(this.timeBox);
    }

    setValue(val: Type.DateTime): void {
        if (Type.DATE_TIME.isValidValue(val)) {
            const timezone = val.timezone ? val.timezone : this.getTimezoneId();
            const { date, time } = split(val, timezone as TimezoneN);
            if (Type.DATE_TIME.hasPrecision(val, P.HOUR)) {
                this.timeBox.setTime(time.lower);
            } else {
                this.timeBox.reset();
            }
            this.setTimezone(timezone as TimezoneN);
            this.updateTimezonesVisibility();
            this.dateBox.setValue(date);
            this.monthBox.setValue(date);
            this.yearBox.setValue(date);
        }
    }

    getTimezoneId(): TimezoneN {
        return this.timezoneId ? this.timezoneId : this.defaultTimezoneId();
    }

    /**
     * Returns the default timezone id by the following precedence (high to low):
     *  - this.localTimezoneId
     *  - current project timezone
     *  - UTC
     */
    private defaultTimezoneId(): TimezoneN {
        if (this.localTimezoneId) {
            return this.localTimezoneId;
        }
        if (Project.CURRENT && Project.CURRENT.timezoneId) {
            return Project.CURRENT.timezoneId as TimezoneN;
        }
        return "UTC" as TimezoneN;
    }

    // set timezone for all date widgets
    setTimezone(timezone?: TimezoneN, skipTzWidget?: boolean) {
        timezone = timezone ? timezone : this.defaultTimezoneId();
        this.timezoneId = timezone;
        !skipTzWidget && this.tzWidget && this.tzWidget.setTimezone(timezone);
        this.dateBox && (this.dateBox.timezone = timezone);
        this.monthBox && (this.monthBox.timezone = timezone);
        this.yearBox && (this.yearBox.timezone = timezone);
        this.setTimezoneInfo(timezone);
    }

    protected setTimezoneInfo(timezone) {
        if (this.editTimezonesButton) {
            // Using full display
            Dom.setContent(this.tzInfo, [
                Project.CURRENT && Project.CURRENT.timezoneId === timezone
                    ? "Using the project timezone: "
                    : "Using timezone: ",
                Dom.br(),
                timezone,
                " ",
                this.editTimezonesButton,
            ]);
        } else {
            // Short display; just show the timezone
            Dom.setContent(this.tzInfo, timezone);
        }
    }

    private getOrCreateTzWidget() {
        if (this.tzWidget) {
            return this.tzWidget;
        }
        const onSelect = (val) => {
            this.setTimezone(this.tzWidget.getTimezoneId() as TimezoneN, true);
            this.tzWidget.blur();
            this.focus();
            !!this.onChange && this.onChange(val);
        };
        this.tzWidget = new TimezoneSelect.SelectNO({
            showOFirst: false,
            n: { onSelect },
            o: { onSelect },
        });
        Dom.addClass(this.tzWidget, "date-time-widget__timezone-select");
        this.tzLW = new DateSearchWidget.LabeledWidget(this.tzWidget);
        this.tzLW.placeIn(this.tzWidgetContainer, false, "Timezone");
        this.registerDestroyable(this.tzWidget);
        this.tzWidget.setTimezone(this.defaultTimezoneId());
        this.tzWidget.showTimezoneSelectN(true); //always ignore timezone offset
        return this.tzWidget;
    }

    protected updateTimezonesVisibility() {
        // hide the timezone selector when the timebox is empty
        if (!this.modeIs(Mode.BOTH) || !this.timeBox.getValue()) {
            Dom.hide(this.tzInfo);
            Dom.hide(this.tzWidgetContainer);
            return;
        }
        if (
            this.tzWidget
            && Project.CURRENT
            && this.tzWidget.getTimezoneId() !== Project.CURRENT.timezoneId
        ) {
            this.hasEverEditedTimezone = true;
        }
        const show = this.hasEverEditedTimezone;
        Dom.show(this.tzInfo, !show);
        Dom.show(this.tzWidgetContainer, !!show);
    }

    protected updateInputsTable() {
        this.updateTimezonesVisibility();
        if (this.modeIs(Mode.BOTH)) {
            this.dateLW.placeIn(
                this.dateboxContainer,
                false,
                this.dateBox.getForm().input.getLabel(),
            );
            this.timeLW.placeIn(this.timeboxContainer, false, this.timeBox.input.getLabel());
            if (this.timezoneId) {
                this.dateBox.timezone = this.timezoneId as DateUtil.TimezoneN;
            } else {
                this.dateBox.timezone = this.defaultTimezoneId() as DateUtil.TimezoneN;
            }
            this.monthLW.hide();
            this.yearLW.hide();
            this.currBox = this.dateBox;
        } else if (this.modeIs(Mode.DATE_ONLY)) {
            this.dateLW.placeIn(
                this.dateboxContainer,
                false,
                this.dateBox.getForm().input.getLabel(),
            );
            this.monthLW.hide();
            this.timeLW.hide();
            this.yearLW.hide();
            this.currBox = this.dateBox;
        } else if (this.modeIs(Mode.MONTH_ONLY)) {
            this.monthLW.placeIn(
                this.dateboxContainer,
                false,
                this.monthBox.getForm().input.getLabel(),
            );
            this.dateLW.hide();
            this.timeLW.hide();
            this.yearLW.hide();
            this.currBox = this.monthBox;
        } else if (this.modeIs(Mode.YEAR_ONLY)) {
            this.yearLW.placeIn(
                this.dateboxContainer,
                false,
                this.yearBox.getForm().input.getLabel(),
            );
            this.dateLW.hide();
            this.timeLW.hide();
            this.monthLW.hide();
            this.currBox = this.yearBox;
        }
    }

    modeIs(mode: Mode) {
        return !!this.mode && this.mode === mode;
    }

    setMode(mode: Mode) {
        this.mode = mode;
        this.updateInputsTable();
    }

    setOnBlur(onBlur: () => void) {
        this.onBlur = () => {
            onBlur();
        };
    }

    setOnChange(onChange: (val) => void) {
        this.onChange = onChange;
    }

    setFocusGroup(parent: FocusContainerWidget) {
        FocusGrouping.adopt(parent, this.dateBox.input);
        FocusGrouping.adopt(parent, this.dateBox);
        FocusGrouping.adopt(parent, this.monthBox.input);
        FocusGrouping.adopt(parent, this.yearBox.input);
        FocusGrouping.adopt(parent, this.timeBox);
    }

    getDateBox() {
        return this.currBox;
    }

    getValue() {
        // Construct date time object here
        // When there are only date and no time, the returned timestamp should be based on
        // the default timezone instead of the user selected timezone to avoid imprecision.
        const timestamp = this.currBox.getValue(
            this.modeIs(Mode.BOTH) && this.timeBox.isEmpty()
                ? this.defaultTimezoneId()
                : this.getTimezoneId(),
        );
        // if not fully filled out, then null
        if (timestamp === null) {
            return null;
        }

        if (this.modeIs(Mode.BOTH)) {
            if (this.timeBox.isEmpty()) {
                // allow user to omit a time, same as dateOnly
                return {
                    ...timestamp,
                    type: PrecisionName.dateOnly,
                    timezone: this.defaultTimezoneId(), // ignore user selected timezone for imprecise dates
                };
            } else if (!this.timeBox.isValid()) {
                return null;
            }
            // need to add time as well
            timestamp.lower = timestamp.lower + this.timeBox.getParsedValue().msecOfDay;
            return {
                ...timestamp,
                precision: Precision.dateTime,
                timezone: this.getTimezoneId(),
            };
        } else if (this.modeIs(Mode.DATE_ONLY)) {
            return {
                ...timestamp,
                type: PrecisionName.dateOnly,
                timezone: this.defaultTimezoneId(),
            };
        } else if (this.modeIs(Mode.MONTH_ONLY)) {
            return {
                ...timestamp,
                type: PrecisionName.monthYearOnly,
                timezone: this.defaultTimezoneId(),
            };
        } else {
            // year only
            return {
                ...timestamp,
                type: PrecisionName.yearOnly,
                timezone: this.defaultTimezoneId(),
            };
        }
    }

    isValid() {
        if (this.modeIs(Mode.BOTH)) {
            if (this.currBox.input.isEmpty() && this.timeBox.isEmpty()) {
                return true; // both empty is valid
            }
            // valid date + (optional) time is valid
            return !!this.currBox.getDate() && (this.timeBox.isEmpty() || this.timeBox.isValid());
        } else {
            return this.currBox.input.isEmpty() || !!this.currBox.getDate();
        }
    }

    getTimeBox() {
        return this.timeBox;
    }

    isEmpty() {
        if (this.modeIs(Mode.BOTH)) {
            return this.currBox.input.isEmpty() && this.timeBox.isEmpty();
        } else {
            return this.currBox.input.isEmpty();
        }
    }

    clear() {
        this.currBox.clear();
        this.timeBox.setValue("");
    }

    clearAll() {
        this.dateBox.clear();
        this.monthBox.clear();
        this.yearBox.clear();
        this.timeBox.setValue("");
        this.setMode(this.initialMode);
    }
}

// used in dialog
export class DateTimeFormWide extends DateTimeForm {
    protected override initDOM(attrs?: any) {
        this.node = Dom.div({ ...attrs, class: "date-time-widget--dialog" }, [
            Dom.table(
                { class: "date-time-widget__inputs-table" },
                Dom.create("colgroup", {
                    content: [Dom.create("col", { span: 2, style: { width: "60px" } })],
                }),
                Dom.create("tbody", {
                    content: [
                        Dom.tr([
                            (this.dateboxContainer = Dom.td({ class: "date-time-widget__col" })),
                            (this.timeboxContainer = Dom.td({ class: "date-time-widget__col" })),
                        ]),
                    ],
                }),
            ),
            (this.tzWidgetContainer = Dom.div({ class: "date-time-widget__timezone-container" })),
            (this.tzInfo = Dom.div({ class: "date-time-widget__timezone-info" })),
        ]);
        this.editTimezonesButton = Dom.a("Edit");
    }

    protected override initLabelledWidgets() {
        this.dateLW = new DateSearchWidget.LabeledWidget(this.dateBox, "vertical");
        this.monthLW = new DateSearchWidget.LabeledWidget(this.monthBox, "vertical");
        this.yearLW = new DateSearchWidget.LabeledWidget(this.yearBox, "vertical");
        this.timeLW = new DateSearchWidget.LabeledWidget(this.timeBox, "vertical");
    }

    protected override setTimezoneInfo(timezone) {
        if (this.editTimezonesButton) {
            // Using full display
            Dom.setContent(
                this.tzInfo,
                Dom.div([
                    Project.CURRENT && Project.CURRENT.timezoneId === timezone
                        ? "Using the project timezone: "
                        : "Using timezone: ",
                    timezone,
                    " ",
                    this.editTimezonesButton,
                ]),
            );
        } else {
            // Short display; just show the timezone
            Dom.setContent(this.tzInfo, Dom.div(timezone));
        }
    }
}

// a wrapper class for DateTimeForm when embedding it into a popup
export abstract class DateTimeWidget extends FocusContainerWidget {
    // Actual UI sub-widgets
    datetimeForm: DateTimeForm;

    // For focusing the "entire" widget
    private hiddenInput: HTMLInputElement;

    // For sending its value for use
    submit: (time: Type.DateTime) => void;

    protected popup: Popup;

    constructor(
        div: HTMLElement,
        ref: HTMLElement,
        onSubmit: (time: Type.DateTime) => void,
        localTimezone: boolean,
        initialMode: Mode,
        // When this DTW is focused, should it then immediately shift focus to a sub-widget?
        private focusInner: boolean,
    ) {
        super(div);
        // Base class only renders the top level node and the hiddenInput
        // All other components should be rendered in subclasses
        Dom.place(
            Dom.div(
                { style: { width: 0, height: 0, overflow: "hidden" } },
                (this.hiddenInput = Dom.input()),
            ),
            this.node,
        );
        // In particular, datetimeForm should be rendered in subclasses
        this.datetimeForm = new DateTimeForm({
            mode: initialMode,
            localTimezone,
        });

        this.submit = onSubmit;
        this.popup = new Popup({
            content: this.node,
            direction: "before",
            reference: ref,
            zIndex: "999",
            matchWidth: false,
        });
        const closeWidget = () => {
            this.onBlur();
            this.hide();
        };
        this.datetimeForm.setOnBlur(closeWidget);

        // for focus so clicking on calendar doesn't blur the whole thing
        this.datetimeForm.setFocusGroup(this);
        this.adjustFocus();

        this.registerDestroyable([this.popup, this.datetimeForm]);
    }

    // Fixes the issue of the widget closing upon date selection without selecting a time
    private adjustFocus() {
        Dom.setAttr(this.node, "tabindex", "-1");
        this.registerDestroyable(
            dojo_on(this.node, "focus", () => {
                this.hiddenInput.focus();
            }),
        );
    }

    override onBlur() {
        super.onBlur();
        this.popup.hide();
        // handle value submit
        const val = this.getValue();
        if (Is.defined(this.submit)) {
            this.submit(val);
        }
    }

    // Call this after DOM setup, otherwise popup shows in a different place
    override focus() {
        this.popup.reference.focus();
        this.popup.show();
        if (this.focusInner) {
            this.datetimeForm.getDateBox().focus();
        } else {
            this.hiddenInput.focus();
        }
    }

    setFocusInner(value: boolean) {
        this.focusInner = value;
    }

    hide() {
        this.popup.hide();
    }

    movePopup(reference: HTMLElement) {
        this.popup.reference = reference;
    }

    setMode(mode: Mode) {
        this.datetimeForm.setMode(mode);
    }

    setDirection(direction: "before" | "after") {
        this.popup.direction = direction;
    }

    setWidth(width: string) {
        this.node.style.width = width;
    }

    getValue() {
        return this.datetimeForm.getValue();
    }

    getDateBox() {
        return this.datetimeForm.getDateBox();
    }

    clear() {
        this.datetimeForm.clear();
    }

    clearAll() {
        this.datetimeForm.clearAll();
    }

    isValid() {
        return this.datetimeForm.isValid();
    }

    isEmpty() {
        return this.datetimeForm.isEmpty();
    }

    moveX(x: number) {
        this.popup.setOffset({ x: x, y: 0 });
    }

    moveY(y: number) {
        this.popup.setOffset({ x: 0, y: y });
    }

    setValue(val: Type.DateTime) {
        this.datetimeForm.setValue(val);
    }

    getParsingFormats() {
        return this.datetimeForm.getParsingFormats();
    }

    getTimeBox() {
        return this.datetimeForm.getTimeBox();
    }
}

// the compact widget used in the review window and Story Builder
export class DateTimeWidgetCompact extends DateTimeWidget {
    constructor(
        ref: HTMLElement,
        onSubmit?: (time: Type.DateTime) => void,
        localTimezone: boolean = false,
        initialMode: Mode = Mode.BOTH,
        focusInner = false,
    ) {
        // basic DOM setup here
        super(
            Dom.div({ class: "date-time-widget" }),
            ref,
            onSubmit,
            localTimezone,
            initialMode,
            focusInner,
        );
        Dom.place(this.datetimeForm.getNode(), this.node);
        this.focus();
    }
}

// the full date time widget with radio buttons
export class DateTimeWidgetFull extends DateTimeWidget {
    //the radio button on the left
    private modesRadio: BasicRadio;

    constructor(
        ref: HTMLElement,
        onSubmit?: (time: Type.DateTime) => void,
        dateModesOnly: boolean = false,
        localTimezone: boolean = false,
        initialMode: Mode = Mode.DATE_ONLY,
        focusInner = false,
    ) {
        // basic DOM setup here
        super(
            Dom.div({ class: "date-time-widget--full" }),
            ref,
            onSubmit,
            localTimezone,
            initialMode,
            focusInner,
        );
        // "after" works better in Story Builder
        this.popup.direction = "after";
        this.modesRadio = dateModesOnly
            ? new BasicRadio(dateModes, true)
            : new BasicRadio(allModes, true);
        this.modesRadio.onChange = (mode) => {
            this.datetimeForm.setMode(mode.id as Mode);
        };
        this.modesRadio.select(initialMode);
        Dom.place(
            Dom.table(
                { class: "dsw-main", style: { width: "350px", maxWidth: "380px" } },
                Dom.td({ class: "dsw-left", style: "width:128px" }, this.modesRadio.getNode()),
                Dom.td(
                    {
                        class: "dsw-right",
                        style: {
                            width: "200px",
                            maxWidth: "200px",
                            textAlign: "left",
                            paddingRight: "12px",
                        },
                    },
                    this.datetimeForm.getNode(),
                ),
            ),
            this.node,
        );
        this.focus();
        this.registerDestroyable(this.modesRadio);
    }

    override setMode(mode: Mode) {
        super.setMode(mode);
        this.modesRadio.select(mode);
    }
}

/**
 * This is basically a compact DateTimeWidget but instead of serving as a popup
 * this widget is a div element that can be placed statically in a parent div.
 */
export class DateTimeWidgetNoLabels {
    node: HTMLElement;
    dateBox: DateBox;
    dateBoxParams: DateBox.Params;
    timeBox: Validated.Time;
    timeBoxParams: Validated.DateWidgetParams;
    tzNoticeDiv: HTMLElement;
    tzSelect: TimezoneSelect.SelectNO;
    width: string;
    dateAndTimeBoxWidth: string;
    private toDestroy: Util.Destroyable[] = [];

    constructor(params: DateTimeWidgetNoLabelsParams) {
        if (params.width && params.width.substring(params.width.length - 2) === "px") {
            this.width = params.width;
        } else {
            this.width = "232px";
        }
        this.dateAndTimeBoxWidth = (parseInt(this.width) - 8) / 2 + "px";
        this.dateBoxParams = {
            placeholder: "yyyy/mm/dd",
            onChange: (val) => {
                return;
            },
        };
        this.timeBoxParams = {
            name: "time box",
            onChange: (val) => {
                return;
            },
            required: false,
        };
        if (params.extraDateBoxParams) {
            this.dateBoxParams = { ...this.dateBoxParams, ...params.extraDateBoxParams };
        }
        if (params.extraTimeBoxParams) {
            this.timeBoxParams = { ...this.timeBoxParams, ...params.extraTimeBoxParams };
        }
        this.dateBoxParams.width = this.dateAndTimeBoxWidth;
        this.timeBoxParams.width = this.dateAndTimeBoxWidth;
        this.dateBox = new DateBox(this.dateBoxParams);
        this.timeBox = new Validated.Time(this.timeBoxParams);
        const onSelect = (val) => {
            this.tzSelect.blur();
        };
        this.tzSelect = new TimezoneSelect.SelectNO({
            n: { onSelect },
            o: { onSelect },
        });
        Dom.hide(this.tzSelect);
        const editTimezonesButton = Dom.a({ class: "text-action" }, "Edit");
        this.tzNoticeDiv = Dom.div(
            Project.CURRENT && Project.CURRENT.timezoneId === this.tzSelect.getTimezoneId()
                ? "Using the project timezone: "
                : "Using timezone: ",
            Dom.br(),
            this.tzSelect.getTimezoneId(),
            " ",
            editTimezonesButton,
        );
        this.toDestroy.push(
            dojo_on(editTimezonesButton, Input.tap, () => {
                Dom.hide(this.tzNoticeDiv);
                Dom.show(this.tzSelect);
            }),
        );
        Dom.style(this.tzSelect, "width", this.width);
        const upperDiv = Dom.div(
            { class: "date-time-box-config" },
            Dom.node(this.dateBox),
            Dom.node(this.timeBox),
        );
        this.node = Dom.div(
            upperDiv,
            Dom.node(this.tzSelect),
            this.tzNoticeDiv,
            Dom.node(this.tzSelect),
        );
    }
    getValue(): Type.DateTime {
        // Construct date time object here
        // if not fully filled out, then null
        if (this.dateBox.isEmpty() || !this.timeBox.isValid()) {
            return null;
        }
        const timestamp = this.dateBox.getValue(this.tzSelect.getTimezone() as TimezoneN);
        if (!timestamp) {
            return null;
        }
        // need to add time as well
        timestamp.lower = this.timeBox.isEmpty()
            ? timestamp.lower
            : timestamp.lower + this.timeBox.getParsedValue().msecOfDay;
        return {
            ...timestamp,
            precision: Precision.dateTime,
            timezone: this.tzSelect.getTimezoneId(),
        };
    }
    getDisplay(): string {
        return this.getValue() ? Type.DATE_TIME.displayValue(this.getValue()) : "";
    }
    getNode(): HTMLElement {
        return this.node;
    }
    setValue(val: Type.DateTime): void {
        if (Type.DATE_TIME.isValidValue(val)) {
            const timezone = val.timezone ? val.timezone : "UTC";
            const { date, time } = split(val, timezone as TimezoneN);
            if (Type.DATE_TIME.hasPrecision(val, P.HOUR)) {
                this.timeBox.setTime(time.lower);
            } else {
                this.timeBox.reset();
            }
            this.tzSelect.setTimezone(timezone as TimezoneNO);
            Dom.show(
                this.tzNoticeDiv,
                Project.CURRENT?.timezoneId === this.tzSelect.getTimezoneId(),
            );
            Dom.hide(this.tzSelect, Project.CURRENT?.timezoneId === this.tzSelect.getTimezoneId());
            this.dateBox.setValue(date);
        }
    }
    destroy(): void {
        Util.destroy(this.toDestroy);
        this.toDestroy = [];
    }
}

export interface DateTimeWidgetNoLabelsParams {
    /** Width of the whole widget, this must be supplied as a px value or
        the widget will default to 232px */
    width?: string;
    /** Parameters to pass to the DateBox, TimeBox, and TimezoneBox.
        Note that the width values will be overridden so the
        DateBox and TimeBox will have width ((width of widget - 8px) / 2)px
        and the TimezoneBox will be the whole width of the widget.

        Any other inputted values will override the default. */
    extraDateBoxParams?: DateBox.Params;
    extraTimeBoxParams?: Partial<Validated.DateWidgetParams>;
}
export interface DateTimeWidgetParams {
    mode?: Mode;
    localTimezone?: boolean;
    attrs?: any;
    noLabels?: boolean;
    noEditButton?: boolean;
}
